import React from 'react';
import Layout from "../components/layout";
import Testimonial from "../components/home/testimonial";
import Banner from "../components/workflow/banner";
import WorkflowSection from "../components/workflow/workflow-section";
import WorkflowBox from "../components/workflow/workflow-box";
import SEO from "../components/seo";

const WorkFlow = (props) => (
    <Layout {...props}>
          <SEO title="Workflow" />
          <div class="col-12 wrapper main-bggrey p-0">
              <div class="col-12 inner-page-content home-inner  px-0">
                  <Banner/>
              </div>
          </div>
          <WorkflowSection/>
          <WorkflowBox/>
          <Testimonial/>
    </Layout>
)

export default WorkFlow;


